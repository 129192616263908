<script setup lang="ts">
import { ButtonComponent, IconComponent, PopoverComponent } from '@shipitsmarter/viya-ui-warehouse';
import { useVModel } from '@vueuse/core';
import { computed, ref } from 'vue';

import { ROUTES } from '@/router/helpers/routes';

export type BreadcrumbLocationType = {
  reference: string;
  label: string;
};
const props = defineProps<{
  modelValue: BreadcrumbLocationType | undefined;
  options: BreadcrumbLocationType[];
  isLoaded: boolean;
}>();

const emit = defineEmits(['update:modelValue']);
const selectedLocation = useVModel(props, 'modelValue');
const popoverOpen = ref(false);
const isSearchable = computed(() => props.options.length > 10);
</script>

<template>
  <div v-if="!isLoaded" class="text-sm">
    <IconComponent name="PhSpinner" class="rotation" />
  </div>
  <div v-else class="mr-2 flex">
    <RouterLink v-if="!selectedLocation" :to="{ name: ROUTES.LOCATION_CREATE.name }">
      <ButtonComponent
        @click="emit('update:modelValue', 'new')"
        data-testid="add-location"
        type="button"
        size="xs"
        variant="secondary"
        value-key="reference"
        label="Add location"
      />
    </RouterLink>

    <PopoverComponent
      v-model:open="popoverOpen"
      v-model="selectedLocation"
      v-else
      :options="options"
      name-key="label"
      :searchable="isSearchable"
    >
      <div class="flex h-6 items-center gap-2 whitespace-nowrap rounded bg-darker px-2">
        <span class="text-xs">{{ selectedLocation?.label }}</span>
        <IconComponent name="PhArrowsLeftRight" size="md" />
      </div>
    </PopoverComponent>
  </div>
</template>

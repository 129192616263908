import { BaseService, DataOrProblem, Options } from '@/services/base-service';
import { ApiClient } from '@/services/clients';

const parentPath = '/configs/v1/feature-flags';

// redoc at https://stpviyacontracts.z6.web.core.windows.net/?swagger=%252Fconfigs%252Fswagger.json#tag/FeatureFlag
export class FeatureFlagService extends BaseService {
  public async readSingle(
    reference: string,
    ...args: Options<boolean>
  ): Promise<DataOrProblem<boolean>> {
    return await this.executeRequest<boolean>(
      async () =>
        await ApiClient.GET(`${parentPath}/{reference}`, {
          params: { path: { reference } },
        }),
      ...args
    );
  }
}

<template>
  <div
    class="relative flex flex-col bg-gray-200"
    :class="showFullSize ? 'w-64 min-w-64' : 'min-w-18'"
  >
    <div class="absolute -right-3 top-10 z-50">
      <TooltipComponent :content="showFullSize ? 'minimise' : 'expand'" direction="right">
        <ButtonComponent
          @click="toggleSidebar"
          size="xs"
          class="rounded-full bg-white"
          variant="ghost"
          :icon="showFullSize ? 'PhCaretLeft' : 'PhCaretRight'"
        />
      </TooltipComponent>
    </div>
    <div class="sticky top-0">
      <div id="logo" class="px-4 py-4">
        <RouterLink to="/">
          <svg
            class="h-7 w-auto fill-teal-600"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 118.95 87.74"
          >
            <path
              d="M107.59,10.18C86.2-8.31,59.73-1.83,50.74,31.42h-2.48v-5.32C48.17,11.54,35.24-.03,20.29,2.32,8.14,4.22-.73,15.62.05,27.9c.39,6.24,3,11.93,7.44,16.16,5.65,5.37,13.63,8.1,22.62,7.66,1.18-.06,2.33-.15,3.47-.28v6.75c0,12.4,7.5,23.96,19.23,27.96,3.17,1.08,6.37,1.6,9.51,1.6,9,0,17.47-4.3,22.98-12.08l31.89-45.02c2.77-3.9,2.24-9.25-1.23-12.54l-8.36-7.92s0,0,0,0ZM33.58,36.62c-1.33.21-2.73.35-4.19.42-4.78.26-9.1-1.08-11.76-3.62-1.91-1.82-2.9-4.19-2.94-7.02-.04-3.8,2.15-7.52,5.68-8.93,6.76-2.71,13.21,2.29,13.21,8.78v10.38ZM73.3,67.17c-4.3,6.07-10.79,6.64-15.43,5.17-4.64-1.48-9.61-5.71-9.61-13.14v-11.97c7.95-3.97,14.18-10.53,18.6-19.62,2.58-5.3,7.08-11.89,13.85-13.16,5.21-.98,11.37,1.39,16.82,6.46l5.11,4.84-29.35,41.43Z"
              stroke-width="0"
            />
          </svg>
        </RouterLink>
      </div>

      <div id="highlighted-menu" class="mx-3 border-b border-b-default pb-6 pt-10">
        <TooltipComponent
          v-for="(item, index) in navTopItems"
          :key="item.route"
          :content="item.displayName"
          direction="right"
          :hidden="showFullSize"
          :delay="700"
        >
          <button
            @click="() => navigateTo(item)"
            active-class="!text-accent bg-gray-300"
            :class="hasAccess('CREATE_SHIPMENT')"
            class="flex w-full items-center gap-3 rounded-lg px-3 py-3 text-muted hover:bg-gray-300 hover:text-accent hover:no-underline"
            :data-testid="item.testId || `nav-btn-${index}`"
          >
            <IconComponent :name="item.icon as IconName" size="xl" />
            <span v-if="showFullSize" class="font-semibold">{{ item.displayName }}</span>
          </button>
        </TooltipComponent>
      </div>
      <div class="relative">
        <div class="absolute w-full">
          <div id="menu" class="flex flex-col gap-2 px-3 py-6">
            <template v-if="!showConfig">
              <NavSideBarButton
                v-for="item in navItems"
                :key="item.route.name"
                @router-click="showConfig = false"
                :route-link="item.route"
                :icon="item.icon"
                :label="item.displayName"
                :show-label="showFullSize"
                :dev-mode="item.devMode"
                :class="hasAccess(item.hasAccessString)"
                class="mb-2"
              />
            </template>
            <TooltipComponent
              v-if="!showConfig"
              content="Configuration"
              direction="right"
              :hidden="showFullSize"
              :delay="700"
            >
              <!--              old start-->
              <div
                @click="handleConfigClick"
                class="flex w-full items-center gap-3 rounded px-3 py-3 text-left text-muted hover:cursor-pointer hover:bg-gray-300 hover:text-accent hover:no-underline"
                :class="hasAccess('EDIT_CONFIG')"
              >
                <IconComponent name="PhGearSix" size="xl" />
                <span v-if="showFullSize" class="grow font-semibold">Configuration</span>
                <IconComponent v-if="!showConfig && showFullSize" name="PhPlus" />
              </div>
              <!--              old end-->
            </TooltipComponent>
          </div>
        </div>
        <div class="absolute w-full px-3">
          <!-- Horizontal MENU -->
          <div id="menu">
            <div v-if="showConfig" class="flex flex-row justify-start gap-2 py-6">
              <NavSideBarButton
                v-for="item in navItems"
                :key="item.route.name"
                @router-click="showConfig = false"
                :route-link="item.route"
                :icon="item.icon"
                :label="item.displayName"
                :show-label="!showConfig"
                :dev-mode="item.devMode"
                :class="hasAccess('EDIT_CONFIG')"
              />
            </div>
          </div>
          <div
            v-if="showConfig"
            @click="handleConfigClick"
            class="flex w-full items-center gap-3 rounded py-3 pl-3 pr-3 text-left text-muted hover:cursor-pointer hover:bg-gray-300 hover:text-accent hover:no-underline"
          >
            <IconComponent name="PhGearSix" size="xl" />
            <span class="grow font-semibold">Configuration</span>
            <IconComponent v-if="!showConfig" name="PhPlus" size="xl" />
            <IconComponent v-if="showConfig" name="PhX" />
          </div>
          <template v-if="showConfig">
            <NavSideBarButton
              v-for="(configItem, index) in configMenu"
              :key="index"
              :route-link="configItem.route"
              :icon="configItem.icon"
              :label="configItem.displayName"
              :show-label="true"
              :index="index"
              :dev-mode="configItem.devMode"
              config-menu
            />
          </template>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import {
  ButtonComponent,
  IconComponent,
  IconName,
  TooltipComponent,
} from '@shipitsmarter/viya-ui-warehouse';
import posthog from 'posthog-js';
import { computed, ref } from 'vue';
import { useRouter } from 'vue-router';

import { AccessKey, hasAccess } from '@/composables/useHasAccess.ts';
import { ROUTES } from '@/router/helpers/routes';
import { useContextStore } from '@/store';
import { sendTrackingEvent } from '@/utils/logger';

import NavSideBarButton from './NavSideBarButton.vue';

const contextStore = useContextStore();
const router = useRouter();

type NavItem = {
  icon: string;
  displayName: string;
  route: { name: string; params?: Record<string, string | number> };
  highlight?: boolean;
  hasAccessString?: keyof typeof AccessKey;
  devMode?: boolean;
  betaMode?: boolean;
  testId?: string;
};

const showFullSize = ref(false);
const showConfig = ref(false);

const navTopItems = ref<NavItem[]>([
  {
    icon: ROUTES.SHIPMENT_CREATE.icon,
    displayName: ROUTES.SHIPMENT_CREATE.title,
    route: { name: ROUTES.SHIPMENT_CREATE.name },
    highlight: true,
    testId: 'create-shipment-btn',
  },
]);
const navItems = ref<NavItem[]>([
  {
    icon: ROUTES.SHIPMENT_LIST.icon,
    displayName: ROUTES.SHIPMENT_LIST.title,
    route: { name: ROUTES.SHIPMENT_LIST.name },
    hasAccessString: 'VIEW_SHIPMENTS',
  },
  {
    icon: ROUTES.CONSIGNMENT_LIST.icon,
    displayName: ROUTES.CONSIGNMENT_LIST.title,
    route: { name: ROUTES.CONSIGNMENT_LIST.name },
    hasAccessString: 'VIEW_SHIPMENTS',
    devMode: true,
  },
  {
    icon: ROUTES.ADDRESS_LIST.icon,
    displayName: ROUTES.ADDRESS_LIST.title,
    route: { name: ROUTES.ADDRESS_LIST.name },
    hasAccessString: 'EDIT_CONFIG',
  },
]);

const configMenu = computed(() => {
  return [
    {
      icon: ROUTES.LOCATION_LIST.icon,
      displayName: ROUTES.LOCATION_LIST.title,
      route: { name: ROUTES.LOCATION_LIST.name },
    },
    {
      icon: ROUTES.SHIPMENT_CONFIG.icon,
      displayName: ROUTES.SHIPMENT_CONFIG.title,
      route: { name: ROUTES.SHIPMENT_CONFIG.name },
    },
    {
      icon: ROUTES.TOKEN_LIST.icon,
      displayName: ROUTES.TOKEN_LIST.title,
      route: { name: ROUTES.TOKEN_LIST.name },
    },
    {
      icon: ROUTES.WEBHOOK_LIST.icon,
      displayName: ROUTES.WEBHOOK_LIST.title,
      route: { name: ROUTES.WEBHOOK_LIST.name },
    },
    {
      icon: ROUTES.CARRIER_PROFILE_LIST.icon,
      displayName: ROUTES.CARRIER_PROFILE_LIST.title,
      route: {
        name: ROUTES.CARRIER_PROFILE_LIST.name,
        params: { location: contextStore.getLocation?.reference },
      },
    },
    {
      icon: ROUTES.USER_LIST.icon,
      displayName: ROUTES.USER_LIST.title,
      route: { name: ROUTES.USER_LIST.name },
    },
    {
      icon: ROUTES.REFERENCE_LIST.icon,
      displayName: ROUTES.REFERENCE_LIST.title,
      route: { name: ROUTES.REFERENCE_LIST.name },
    },
    {
      icon: ROUTES.FTP_LIST.icon,
      displayName: ROUTES.FTP_LIST.title,
      route: { name: ROUTES.FTP_LIST.name },
    },
    {
      icon: ROUTES.RATE_CONTRACT_LIST.icon,
      displayName: ROUTES.RATE_CONTRACT_LIST.title,
      route: { name: ROUTES.RATE_CONTRACT_LIST.name },
      devMode: true,
    },
  ];
});

const toggleSidebar = () => {
  const previousValue = showFullSize.value;
  showFullSize.value = !previousValue;
  showConfig.value = false;
  sendTrackingEvent('navigation.sidebar.toggled', {
    to: !previousValue,
  });
};

const handleConfigClick = () => {
  sendTrackingEvent('navigation.item.clicked', {
    path: '/configuration',
  });
  showFullSize.value = !showConfig.value;
  showConfig.value = !showConfig.value;
};

const navigateTo = (item: NavItem) => {
  router.push({ name: item.route.name });
  sendTrackingEvent('navigation.item.clicked', {
    route: item.route.name,
    source: 'sidebar',
    is_sidebar_open: showFullSize.value,
  });
};

posthog.setPersonPropertiesForFlags({ $current_url: window.location.href });

posthog.onFeatureFlags(function () {
  // feature flags should be available at this point
  if (posthog.isFeatureEnabled('consignments')) {
    setDevmodeToFalse('Consignments');
    console.log('devmode set to false');
  }
});

function setDevmodeToFalse(displayName: string) {
  const index = navItems.value.findIndex((item) => item.displayName === displayName);
  if (index !== -1) {
    if (navItems.value[index]) {
      navItems.value[index].devMode = false;
    }
  }
}
</script>

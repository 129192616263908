<script setup lang="ts">
import { IconComponent, PopoverComponent } from '@shipitsmarter/viya-ui-warehouse';
import { useResizeObserver } from '@vueuse/core';
import { storeToRefs } from 'pinia';
import { computed, ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';

import { Route } from '@/router/helpers/routes';
import { useContextStore } from '@/store';
import { sendTrackingEvent } from '@/utils/logger';

import BreadcrumbItem from './BreadcrumbItem.vue';
import BreadcrumbLocation, { BreadcrumbLocationType } from './BreadcrumbLocation.vue';

const router = useRouter();
const route = useRoute();

const { locationsLoaded } = storeToRefs(useContextStore());
const contextStore = useContextStore();

const SPACE = 120;
const currentLocation = computed(() => contextStore.getLocation);
const breadcrumbRef = ref();
const breadcrumbWrapperRef = ref();
const parentRoutes = ref<Route[]>([]);
const breadcrumbsHidden = ref<Route[]>([]);

const handleLocationChange = (location: BreadcrumbLocationType) => {
  contextStore.setLocation(location.reference);
  window.location.reload();
};

const checkOverflow = (scrollWidth: number, clientWidth: number) => {
  return scrollWidth > clientWidth;
};

const hideBreadcrumb = () => {
  const copy = parentRoutes.value!;
  const removedBreadcrumb = copy.shift();

  breadcrumbsHidden.value = [...breadcrumbsHidden.value, removedBreadcrumb];
  parentRoutes.value = copy;
};

const addBreadcrumb = () => {
  const copy = breadcrumbsHidden.value;
  const addedBreadcrumb = copy.shift();

  parentRoutes.value = [...parentRoutes.value, addedBreadcrumb];
  breadcrumbsHidden.value = copy;
};

const goToBreadcrumb = (breadcrumb: Route) => {
  router.push({ name: breadcrumb.name });
  sendTrackingEvent('navigation.breadcrumb.clicked', {
    routeName: breadcrumb.name,
    source: 'breadcrumb-selector',
  });
  breadcrumbsHidden.value = [];
};

useResizeObserver(breadcrumbWrapperRef, (entries) => {
  const entry = entries[0]!;
  const { width } = entry.contentRect;

  if (breadcrumbsHidden.value.length > 0) {
    const isSpaceToAdd = width - breadcrumbRef.value.clientWidth > SPACE;
    if (isSpaceToAdd) {
      addBreadcrumb();
    }
  }
});

useResizeObserver(breadcrumbRef, (entries) => {
  const entry = entries[0]!;
  const { scrollWidth, clientWidth } = entry.target;

  const isOverflown = checkOverflow(scrollWidth, clientWidth);
  if (isOverflown) {
    hideBreadcrumb();
  }
});

watch(route, () => {
  parentRoutes.value = Object.values(route.meta.parents || {});
});
</script>

<template>
  <div ref="breadcrumbWrapperRef" class="flex min-w-0 flex-1">
    <div ref="breadcrumbRef" class="flex min-w-0 items-center gap-1">
      <BreadcrumbLocation
        @update:model-value="handleLocationChange"
        :model-value="currentLocation"
        :options="contextStore.getLocations"
        :is-loaded="locationsLoaded"
      />
      <template v-if="currentLocation">
        <div v-if="breadcrumbsHidden.length > 0">
          <PopoverComponent
            @selected="goToBreadcrumb"
            :options="breadcrumbsHidden"
            name-key="title"
          >
            <div class="flex items-center gap-1">
              <span class="text-xs text-muted">/</span>
              <div
                class="flex h-6 items-center justify-between gap-2 rounded px-2 text-muted hover:bg-darker"
              >
                <span>...</span>
                <IconComponent name="PhCaretDown" size="md" />
              </div>
            </div>
          </PopoverComponent>
        </div>

        <template v-for="item in parentRoutes">
          <BreadcrumbItem v-if="item" :key="item.name" :text="item.title" :route="item.name" />
        </template>
        <BreadcrumbItem :text="route.meta.title" :route="route.name" active />
      </template>
    </div>
  </div>
</template>

import { RouteRecordRaw } from 'vue-router';

import { ROUTES } from './helpers/routes';

const OldRoutes: Array<RouteRecordRaw> = [
  {
    path: '/address',
    redirect: { name: ROUTES.ADDRESS_LIST.name },
  },
  {
    path: '/address/create',
    redirect: { name: ROUTES.ADDRESS_CREATE.name },
  },
  {
    path: '/consignments/create',
    redirect: { name: ROUTES.CONSIGNMENT_CREATE.name },
  },
  {
    path: '/test/consignments/create',
    redirect: { name: ROUTES.CONSIGNMENT_CREATE_TEST.name },
  },
  {
    path: '/consignments/:id/edit',
    redirect: { name: ROUTES.CONSIGNMENT_EDIT.name },
  },
  {
    path: '/consignments/:id',
    redirect: { name: ROUTES.CONSIGNMENT_DETAIL.name },
  },
  {
    path: '/test/consignments/:id',
    redirect: { name: ROUTES.CONSIGNMENT_DETAIL_TEST.name },
  },
  {
    path: '/configuration/ftp-configuration',
    redirect: { name: ROUTES.FTP_LIST.name },
  },
  {
    path: '/configuration/ftp-configuration/create-sending',
    redirect: { name: ROUTES.FTP_CLIENT_CREATE.name },
  },
  {
    path: '/configuration/ftp-configuration/create-receiving',
    redirect: { name: ROUTES.FTP_SERVER_CREATE.name },
  },
  {
    path: '/configuration/ftp-configuration/client/:reference',
    redirect: { name: ROUTES.FTP_CLIENT_EDIT.name },
  },
  {
    path: '/configuration/ftp-configuration/server/:reference',
    redirect: { name: ROUTES.FTP_SERVER_EDIT.name },
  },
  {
    path: '/configuration/ftp-configuration/client/:reference/logs',
    redirect: { name: ROUTES.FTP_CLIENT_LOG_LIST.name },
  },
  {
    path: '/configuration/ftp-configuration/server/:reference/logs',
    redirect: { name: ROUTES.FTP_SERVER_LOG_LIST.name },
  },
  {
    path: '/configuration/ftp-configuration/credentials-request',
    redirect: { name: ROUTES.FTP_CREDENTIAL_REQUEST.name },
  },
  {
    path: '/location/create',
    redirect: { name: ROUTES.LOCATION_CREATE.name },
  },
  {
    path: '/locations/:reference',
    redirect: { name: ROUTES.LOCATION_DETAIL.name },
  },
  {
    path: '/locations/:reference/edit',
    redirect: { name: ROUTES.LOCATION_EDIT.name },
  },
  {
    path: '/shipments/:id',
    redirect: { name: ROUTES.SHIPMENT_DETAIL.name },
  },
  {
    path: '/test/shipments/:id',
    redirect: { name: ROUTES.SHIPMENT_DETAIL_TEST.name },
  },
  {
    path: '/shipments/:id/edit',
    redirect: { name: ROUTES.SHIPMENT_EDIT.name },
  },
  {
    path: '/test/shipments/:id/edit',
    redirect: { name: ROUTES.SHIPMENT_EDIT_TEST.name },
  },
  {
    path: '/shipments/create',
    redirect: { name: ROUTES.SHIPMENT_CREATE.name },
  },
  {
    path: '/test/shipments/create',
    redirect: { name: ROUTES.SHIPMENT_CREATE_TEST.name },
  },
  {
    path: '/tokens',
    redirect: { name: ROUTES.TOKEN_LIST.name },
  },
  {
    path: '/tokens/create',
    redirect: { name: ROUTES.TOKEN_CREATE.name },
  },
  {
    path: '/tokens/:hash',
    redirect: { name: ROUTES.TOKEN_DETAIL.name },
  },
  {
    path: '/user/management',
    redirect: { name: ROUTES.USER_LIST.name },
  },
  {
    path: '/user/management/create',
    redirect: { name: ROUTES.USER_CREATE.name },
  },
  {
    path: '/user/management/:reference',
    redirect: { name: ROUTES.USER_DETAIL.name },
  },
  {
    path: '/user/management/:reference/edit',
    redirect: { name: ROUTES.USER_EDIT.name },
  },
  {
    path: '/carrier-profile/:location/create',
    redirect: { name: ROUTES.CARRIER_PROFILE_CREATE.name },
  },
  {
    path: '/configuration/carrier/:location',
    redirect: { name: ROUTES.CARRIER_PROFILE_LIST.name },
  },
  {
    path: '/configuration/carrier/:location/:carrierProfileReference',
    redirect: { name: ROUTES.CARRIER_PROFILE_EDIT.name },
  },
  {
    path: '/configuration/carrier/:location/:carrierProfileReference/onboarding',
    redirect: { name: ROUTES.CARRIER_PROFILE_ONBOARDING.name },
  },
  {
    path: '/configuration/carrier/:location/:carrierProfileReference/integration/:type',
    redirect: { name: ROUTES.CARRIER_PROFILE_INTEGRATION_EDIT.name },
  },
  {
    path: '/locations',
    redirect: { name: ROUTES.LOCATION_LIST.name },
  },
];

export default OldRoutes;

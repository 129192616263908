import { type RouteRecordRaw } from 'vue-router';

import { ROUTES } from './helpers/routes';

const User: Array<RouteRecordRaw> = [
  {
    path: ROUTES.USER_PROFILE.path,
    name: ROUTES.USER_PROFILE.name,
    component: () => import('@/views/user/UserProfilePage.vue'),
    props: true,
    meta: {
      title: ROUTES.USER_PROFILE.title,
    },
  },
  {
    path: ROUTES.USER_LIST.path,
    name: ROUTES.USER_LIST.name,
    component: () => import('@/views/user/UserOverviewPage.vue'),
    props: true,
    meta: {
      title: ROUTES.USER_LIST.title,
    },
  },
  {
    path: ROUTES.USER_CREATE.path,
    name: ROUTES.USER_CREATE.name,
    component: () => import('@/views/user/UserEditPage.vue'),
    props: true,
    meta: {
      title: ROUTES.USER_CREATE.title,
      parents: { 0: ROUTES.USER_LIST },
    },
  },
  {
    path: ROUTES.USER_DETAIL.path,
    name: ROUTES.USER_DETAIL.name,
    component: () => import('@/views/user/UserDetailPage.vue'),
    props: true,
    meta: {
      title: ROUTES.USER_DETAIL.title,
      parents: { 0: ROUTES.USER_LIST },
    },
  },
  {
    path: ROUTES.USER_EDIT.path,
    name: ROUTES.USER_EDIT.name,
    component: () => import('@/views/user/UserEditPage.vue'),
    props: true,
    meta: {
      title: ROUTES.USER_EDIT.title,
      parents: { 0: ROUTES.USER_LIST },
    },
  },
];

export default User;

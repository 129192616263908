import { type RouteRecordRaw } from 'vue-router';

import { ROUTES } from './helpers/routes';

const AddressV2Routes: Array<RouteRecordRaw> = [
  {
    path: ROUTES.ADDRESS_LIST.path,
    name: ROUTES.ADDRESS_LIST.name,
    component: () => import('../views/address/AddressOverviewPage.vue'),
    meta: {
      title: ROUTES.ADDRESS_LIST.title,
    },
  },
  {
    path: ROUTES.ADDRESS_EDIT.path,
    name: ROUTES.ADDRESS_EDIT.name,
    component: () => import('../views/address/AddressDetailPage.vue'),
    props: {
      mode: 'edit',
    },
    meta: {
      title: ROUTES.ADDRESS_EDIT.title,
      parents: { 0: ROUTES.ADDRESS_LIST },
    },
  },
  {
    path: ROUTES.ADDRESS_CREATE.path,
    name: ROUTES.ADDRESS_CREATE.name,
    component: () => import('../views/address/AddressDetailPage.vue'),
    props: {
      mode: 'create',
    },
    meta: {
      title: ROUTES.ADDRESS_CREATE.title,
      parents: { 0: ROUTES.ADDRESS_LIST },
    },
  },
];
export default AddressV2Routes;

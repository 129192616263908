import { defineComponent } from 'vue';
import { type RouteRecordRaw } from 'vue-router';

import { type AccessMeta } from '@/services/auth/decision';

import { ROUTES } from './helpers/routes';

const GenericRoutes: Array<RouteRecordRaw> = [
  //  redirect to make shipment overview temporary home.
  {
    path: '/',
    // temporary redirect to shipment overview as home.
    redirect: () => {
      return { name: ROUTES.SHIPMENT_LIST.name };
    },
    meta: {
      title: 'home',
      access: {
        url: 'shipments',
        method: 'GET',
        service: 'shipping',
        version: 'v1',
      } as AccessMeta,
    },
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: () => import('../views/NotFoundPage.vue'),
    meta: {
      title: 'Page not found',
      allowAnonymous: true,
    },
  },
  {
    path: '/permission-denied',
    name: 'PermissionDenied',
    component: () => import('../views/PermissionDeniedPage.vue'),
    meta: {
      title: 'You do not have the correct permissions for this page',
      allowAnonymous: true,
    },
  },
  {
    path: '/login',
    name: 'login',
    component: () => defineComponent({}),
    beforeEnter: () => {
      window.location.href = `/auth/ory/ui/login?return_to=${encodeURI(window.location.href)}`;
    },
    meta: {
      allowAnonymous: true,
    },
  },
];

export default GenericRoutes;

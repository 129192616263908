<template>
  <NavigationMenu>
    <NavigationMenuList>
      <NavigationMenuItem>
        <NavigationMenuTrigger class="sm:w-60 md:w-72 lg:w-96">
          <span class="text-default">Quickly find what you need...</span>
        </NavigationMenuTrigger>
        <NavigationMenuContent>
          <ul class="grid w-[400px] gap-3 p-4 md:w-[500px] md:grid-cols-2 lg:w-[600px]">
            <li
              v-for="{ name, accessKey, icon, testMode, title, description } in favoriteItems"
              :key="title"
            >
              <NavigationMenuLink as-child>
                <button
                  @click="() => handleClick(name)"
                  :class="hasAccess(accessKey)"
                  class="w-full select-none items-start space-y-1 rounded p-3 hover:bg-darker focus:bg-darker"
                >
                  <div class="flex items-center gap-2 font-medium leading-none">
                    <IconComponent
                      :name="icon"
                      :class="testMode ? 'text-attention' : 'text-accent'"
                    />
                    {{ title }}
                  </div>
                  <p class="line-clamp-2 text-start text-sm leading-snug text-muted">
                    {{ description }}
                  </p>
                </button>
              </NavigationMenuLink>
            </li>
          </ul>
        </NavigationMenuContent>
      </NavigationMenuItem>
    </NavigationMenuList>
  </NavigationMenu>
</template>

<script setup lang="ts">
import { IconComponent } from '@shipitsmarter/viya-ui-warehouse';
import { useRouter } from 'vue-router';

import {
  NavigationMenu,
  NavigationMenuContent,
  NavigationMenuItem,
  NavigationMenuLink,
  NavigationMenuList,
  NavigationMenuTrigger,
} from '@/components/ui/navigation-menu';
import { AccessKey } from '@/plugins/authorization';
import { ROUTES } from '@/router/helpers/routes';
import { useUserStore } from '@/store/user'; // Assuming the user store is in a separate file
import { sendTrackingEvent } from '@/utils/logger';

const router = useRouter();

type FavoriteItem = {
  title: string;
  name: string;
  icon: string;
  path?: string;
  description?: string;
  accessKey: AccessKey;
  testMode?: boolean;
};

// Define the hasAccess method
const hasAccess = (key: AccessKey): string => {
  const accessStore = useUserStore(); // Call the hook with parentheses

  // Check access and return appropriate string value
  return accessStore.getAccess[key] === false ? 'no-access' : '';
};

const handleClick = (name: string) => {
  sendTrackingEvent('navigation.item.clicked', {
    source: 'quick-find',
    route: name,
  });
  router.push({ name });
};

const favoriteItems: FavoriteItem[] = [
  {
    ...ROUTES.SHIPMENT_LIST,
    description: 'Overview of all shipments',
    accessKey: AccessKey.VIEW_SHIPMENTS,
  },
  {
    ...ROUTES.SHIPMENT_CREATE,
    description: 'Create a new shipment',
    accessKey: AccessKey.CREATE_SHIPMENT,
  },
  {
    ...ROUTES.ADDRESS_LIST,
    description: 'Manage your shipment addresses, for sender, receiver, and more.',
    accessKey: AccessKey.EDIT_CONFIG,
  },
  {
    ...ROUTES.SHIPMENT_CREATE_TEST,
    description: 'To test playground settings',
    accessKey: AccessKey.EDIT_CONFIG,
    testMode: true,
  },
  {
    ...ROUTES.SHIPMENT_LIST_TEST,
    description: 'Overview of all test shipments',
    accessKey: AccessKey.EDIT_CONFIG,
    testMode: true,
  },
];
</script>

//./plugins/posthog.js
import mixpanel from 'mixpanel-browser';
import posthog from 'posthog-js';

const MODE = ['localhost', 'pr-', 'present', 'e2e'].some((str) =>
  window.location.href.includes(str)
)
  ? 'development'
  : 'production';

const MP_PROJECT_ID = {
  development: '1e3d909bd31ec82bf0a524d7c2dc1739',
  production: 'c4f861139822576486123631164ba5e3',
};

const MP_CONFIG = {
  development: {
    debug: true,
    track_pageview: false,
    record_sessions_percent: 1, // set too 100% if you need to test
  },
  production: {
    track_pageview: 'url-with-path-and-query-string',
    api_host: '/mpproxy',
    record_sessions_percent: 100,
  },
};

const PH_PROJECT_ID = {
  development: 'phc_KzgOnqEfyOGApbJHh1uJaD6T1WpkbH5MUt5DLsnzHg0',
  production: 'phc_r3WUJLR2b9x1i0Dpkw8cXQx7kURnbj5BJLS23XeBw9g',
};
const PH_CONFIG = {
  development: {
    api_host: 'https://eu.i.posthog.com',
    autocapture: false,
    loaded: function (ph) {
      ph.opt_out_capturing(); // opts a user out of event capture
      ph.set_config({ disable_session_recording: true });
    },
  },
  production: {
    api_host: '/phproxy',
    ui_host: '/phproxy',
    autocapture: true,
  },
};

export default {
  install(app) {
    app.config.globalProperties.$posthog = posthog.init(PH_PROJECT_ID[MODE], {
      ...PH_CONFIG[MODE],
    });
    app.config.globalProperties.$mixpanel = mixpanel.init(MP_PROJECT_ID[MODE], {
      ...MP_CONFIG[MODE],
      persistence: 'localStorage',
      record_min_ms: 8,
      record_collect_fonts: true,
    });
  },
};

import type { ErrorResponse, MediaType, ResponseObjectMap } from 'openapi-typescript-helpers';
import { Ref } from 'vue';
import { toast } from 'vue-sonner';

import { CoreProblemDetails, toProblem } from '@/services/clients';

export type Res<TResponse extends any | undefined> = {
  data?: TResponse;
  error?: ErrorResponse<ResponseObjectMap<any>, MediaType>;
  response: Response;
};

export type EmptyResponseOptions = [Ref<boolean>?, Ref<CoreProblemDetails | undefined>?];
export type Options<TResponse> = [
  Ref<boolean>?,
  Ref<CoreProblemDetails | undefined>?,
  Ref<TResponse | undefined>?,
];

export abstract class BaseService {
  protected async executeRequest<TResponse extends any | undefined>(
    request: () => Promise<Res<TResponse>>,
    ...args: Options<TResponse>
  ): Promise<DataOrProblem<TResponse>> {
    const [loadingRef, problemRef, valueRef] = args;
    if (loadingRef) loadingRef.value = true;
    if (problemRef) problemRef.value = undefined;

    const response = this.handledResponse<TResponse>(await request());

    if (problemRef) problemRef.value = response.problem; // if success, this will stay undefined
    if (valueRef && response.isSuccess()) valueRef.value = response.data;
    if (loadingRef) loadingRef.value = false;

    return response;
  }

  private handledResponse<TResponse extends any | undefined>(
    res: Res<TResponse>
  ): DataOrProblem<TResponse> {
    if (!res.response.ok) {
      const problem = toProblem(res);
      console.warn(problem);
      return DataOrProblem.fromProblem(problem);
    }

    return DataOrProblem.fromData(res.data as TResponse);
  }
}

export class DataOrProblem<TResponse> {
  private constructor(
    public data: TResponse,
    public problem: CoreProblemDetails | undefined
  ) {}

  public static fromData<TResponse>(data: TResponse): DataOrProblem<TResponse> {
    return new DataOrProblem(data, undefined);
  }

  public static fromProblem<TResponse>(problem: CoreProblemDetails): DataOrProblem<TResponse> {
    return new DataOrProblem(undefined as TResponse, problem);
  }
  public isSuccess = (): boolean => {
    return this.problem === undefined;
  };

  public showToast = (methodString: string, showSuccessPopup: boolean = false): boolean => {
    if (this.problem) {
      toast.error(`Failed to ${methodString}: ${this.problem.title}`);
      return false;
    }

    if (showSuccessPopup) toast.success(`${methodString} successful`);
    return true;
  };
}

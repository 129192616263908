<script setup lang="ts">
import { cn, IconName } from '@shipitsmarter/viya-ui-warehouse';
import { RouteRecordNameGeneric, useRouter } from 'vue-router';

import { sendTrackingEvent } from '@/utils/logger';

defineProps<{
  icon?: IconName;
  text: string | unknown;
  route: RouteRecordNameGeneric;
  active?: boolean;
}>();

const router = useRouter();
const navigateToRoute = (routeName: RouteRecordNameGeneric) => {
  sendTrackingEvent('navigation.breadcrumb.clicked', {
    routeName: routeName,
    source: 'breadcrumb',
  });
  router.push({ name: routeName });
};
</script>

<template>
  <div class="flex items-center gap-1 text-muted">
    <small>/</small>
    <div
      :class="
        cn('flex items-center gap-2 rounded px-2 py-1', {
          'pointer-events-none font-semibold text-default hover:bg-transparent': active,
          'cursor-pointer overflow-hidden': !active,
          'hover:bg-darker': route,
          'pointer-events-none': !route,
        })
      "
    >
      <small @click="() => navigateToRoute(route)" class="whitespace-nowrap">{{ text }}</small>
    </div>
  </div>
</template>

import { type RouteRecordRaw } from 'vue-router';

import { ROUTES } from './helpers/routes';

const Locations: Array<RouteRecordRaw> = [
  {
    path: ROUTES.LOCATION_LIST.path,
    name: ROUTES.LOCATION_LIST.name,
    component: () => import('@/views/locations/LocationOverviewPage.vue'),
    props: true,
    meta: {
      title: ROUTES.LOCATION_LIST.title,
    },
  },
  {
    path: ROUTES.LOCATION_CREATE.path,
    name: ROUTES.LOCATION_CREATE.name,
    component: () => import('@/views/locations/LocationDetailPage.vue'),
    props: true,
    meta: {
      title: ROUTES.LOCATION_CREATE.title,
      parents: { 0: ROUTES.LOCATION_LIST },
    },
  },
  {
    path: ROUTES.LOCATION_DETAIL.path,
    name: ROUTES.LOCATION_DETAIL.name,
    component: () => import('@/views/locations/LocationDetailPage.vue'),
    props: true,
    meta: {
      title: ROUTES.LOCATION_DETAIL.title,
      parents: { 0: ROUTES.LOCATION_LIST },
    },
  },
  {
    path: ROUTES.LOCATION_EDIT.path,
    name: ROUTES.LOCATION_EDIT.name,
    component: () => import('@/views/locations/LocationDetailPage.vue'),
    props: true,
    meta: {
      title: ROUTES.LOCATION_EDIT.title,
      parents: { 0: ROUTES.LOCATION_LIST },
    },
  },
];

export default Locations;

import { type RouteRecordRaw } from 'vue-router';

import { type AccessMeta } from '@/services/auth/decision';

import { ROUTES } from './helpers/routes';

const Shipments: Array<RouteRecordRaw> = [
  {
    path: ROUTES.SHIPMENT_LIST.path,
    name: ROUTES.SHIPMENT_LIST.name,
    component: () => import('@/views/shipment/overview/ShipmentOverviewPage.vue'),
    meta: {
      title: ROUTES.SHIPMENT_LIST.title,
      access: {
        url: 'shipments',
        method: 'GET',
        service: 'shipping',
        version: 'v3',
      } as AccessMeta,
    },
  },
  {
    path: ROUTES.SHIPMENT_LIST_TEST.path,
    name: ROUTES.SHIPMENT_LIST_TEST.name,
    component: () => import('@/views/shipment/overview/ShipmentOverviewPage.vue'),
    meta: {
      title: ROUTES.SHIPMENT_LIST_TEST.title,
      testMode: true,
      access: {
        url: 'shipments',
        method: 'GET',
        service: 'shipping',
        version: 'v3',
      } as AccessMeta,
    },
  },
  {
    path: ROUTES.SHIPMENT_DETAIL.path,
    name: ROUTES.SHIPMENT_DETAIL.name,
    component: () => import('@/views/shipment/details/ShipmentDetailPage.vue'),
    props: true,
    meta: {
      title: ROUTES.SHIPMENT_DETAIL.title,
      parents: { 0: ROUTES.SHIPMENT_LIST },
      access: {
        url: ROUTES.SHIPMENT_DETAIL.path,
        method: 'GET',
        service: 'shipping',
        version: 'v3',
      } as AccessMeta,
    },
  },
  {
    path: ROUTES.SHIPMENT_DETAIL_TEST.path,
    name: ROUTES.SHIPMENT_DETAIL_TEST.name,
    component: () => import('@/views/shipment/details/ShipmentDetailPage.vue'),
    props: true,
    meta: {
      title: ROUTES.SHIPMENT_DETAIL_TEST.title,
      testMode: true,
      parents: { 0: ROUTES.SHIPMENT_LIST },
      access: {
        url: ROUTES.SHIPMENT_DETAIL.path,
        method: 'GET',
        service: 'shipping',
        version: 'v3',
      } as AccessMeta,
    },
  },
  {
    path: ROUTES.SHIPMENT_EDIT.path,
    name: ROUTES.SHIPMENT_EDIT.name,
    component: () => import('@/views/shipment/create/ShipmentCreatePage.vue'),
    props: {
      mode: 'edit',
    },
    meta: {
      title: ROUTES.SHIPMENT_EDIT.title,
      parents: { 0: ROUTES.SHIPMENT_LIST },
      access: {
        url: ROUTES.SHIPMENT_EDIT.path,
        method: 'GET',
        service: 'shipping',
        version: 'v3',
      } as AccessMeta,
    },
  },
  {
    path: ROUTES.SHIPMENT_EDIT_TEST.path,
    name: ROUTES.SHIPMENT_EDIT_TEST.name,
    component: () => import('@/views/shipment/create/ShipmentCreatePage.vue'),
    props: {
      mode: 'edit',
    },
    meta: {
      title: ROUTES.SHIPMENT_EDIT_TEST.title,
      testMode: true,
      parents: { 0: ROUTES.SHIPMENT_LIST_TEST },
      access: {
        url: ROUTES.SHIPMENT_EDIT.path,
        method: 'GET',
        service: 'shipping',
        version: 'v3',
      } as AccessMeta,
    },
  },
  {
    path: ROUTES.SHIPMENT_CREATE.path,
    name: ROUTES.SHIPMENT_CREATE.name,
    component: () => import('@/views/shipment/create/ShipmentCreatePage.vue'),
    props: {
      mode: 'create',
    },
    meta: {
      title: ROUTES.SHIPMENT_CREATE.title,
      parents: { 0: ROUTES.SHIPMENT_LIST },
      access: {
        url: 'shipments',
        method: 'POST',
        service: 'shipping',
        version: 'v3',
      } as AccessMeta,
    },
  },
  {
    path: ROUTES.SHIPMENT_CREATE_TEST.path,
    name: ROUTES.SHIPMENT_CREATE_TEST.name,
    component: () => import('@/views/shipment/create/ShipmentCreatePage.vue'),
    props: {
      mode: 'create',
    },
    meta: {
      title: ROUTES.SHIPMENT_CREATE_TEST.title,
      parents: { 0: ROUTES.SHIPMENT_LIST_TEST },
      testMode: true,
      access: {
        url: 'shipments',
        method: 'POST',
        service: 'shipping',
        version: 'v3',
      } as AccessMeta,
    },
  },

  // Shareable public pages: Order Request (for carriers) and Shipment Tracking (for receivers/end-customers)
  {
    path: ROUTES.SHIPMENT_ORDER_REQUEST_DETAIL.path,
    name: ROUTES.SHIPMENT_ORDER_REQUEST_DETAIL.name,
    component: () => import('@/views/shipment/details/OrderRequestPage.vue'),
    meta: {
      title: ROUTES.SHIPMENT_ORDER_REQUEST_DETAIL.title,
      allowAnonymous: true,
    },
  },
  {
    path: ROUTES.SHIPMENT_ORDER_REQUEST.path,
    name: ROUTES.SHIPMENT_ORDER_REQUEST.name,
    component: () => import('@/views/shipment/details/OrderRequestPage.vue'),
    meta: {
      title: ROUTES.SHIPMENT_ORDER_REQUEST.title,
      allowAnonymous: true,
    },
  },
  {
    path: ROUTES.SHIPMENT_TRACKING_DETAIL.path,
    name: ROUTES.SHIPMENT_TRACKING_DETAIL.name,
    component: () => import('@/views/shipment/details/ShipmentTrackingPage.vue'),
    meta: {
      title: ROUTES.SHIPMENT_TRACKING_DETAIL.title,
      allowAnonymous: true,
    },
  },
];

export default Shipments;

import { type RouteRecordRaw } from 'vue-router';

import { type AccessMeta } from '@/services/auth/decision';

import { ROUTES } from './helpers/routes';

const InternalRoutes: Array<RouteRecordRaw> = [
  // Internal Pages and Design System
  {
    path: ROUTES.INTERNAL_LIST.path,
    name: ROUTES.INTERNAL_LIST.name,
    component: () => import('@/views/internal/InternalOverviewPage.vue'),
    props: true,

    meta: {
      title: ROUTES.INTERNAL_LIST.title,
      access: {
        url: 'internal',
        method: 'GET',
        service: 'configs',
        version: 'v1',
      } as AccessMeta,
    },
  },
  {
    path: ROUTES.INTERNAL_JSON_FORMS.path,
    name: ROUTES.INTERNAL_JSON_FORMS.name,
    component: () => import('@/views/internal/JsonExampleVuePage.vue'),
    props: true,
    meta: {
      title: ROUTES.INTERNAL_JSON_FORMS.title,
      parents: { 0: ROUTES.INTERNAL_LIST },
    },
  },
  {
    path: ROUTES.INTERNAL_JSON_FORMS_PREVIEW.path,
    name: ROUTES.INTERNAL_JSON_FORMS_PREVIEW.name,
    component: () => import('@/views/internal/FormPreviewPage.vue'),
    meta: {
      title: ROUTES.INTERNAL_JSON_FORMS_PREVIEW.title,
      parents: { 0: ROUTES.INTERNAL_LIST },
    },
  },
  {
    path: ROUTES.INTERNAL_OPA_STATUS.path,
    name: ROUTES.INTERNAL_OPA_STATUS.name,
    component: () => import('@/views/internal/OPAOverviewPage.vue'),
    props: true,
    meta: {
      title: ROUTES.INTERNAL_OPA_STATUS.title,
      parents: { 0: ROUTES.INTERNAL_LIST },
    },
  },
];

export default InternalRoutes;

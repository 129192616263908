import { IconName } from '@shipitsmarter/viya-ui-warehouse';

export type Route = {
  name: string;
  title?: string;
  icon?: IconName;
  path?: string;
  params?: Record<string, string>;
};

const ADDRESS_ROUTES = {
  ADDRESS_LIST: {
    name: 'Address:List',
    title: 'Addresses',
    icon: 'PhAddressBook',
    path: '/addresses',
  },
  ADDRESS_EDIT: {
    name: 'Address:Edit',
    title: 'Edit address',
    icon: 'PhAddressBook',
    path: '/address/:id',
  },
  ADDRESS_CREATE: {
    name: 'Address:Create',
    title: 'New address',
    icon: 'PhAddressBook',
    path: '/address/new',
  },
};

const SHIPMENT_ROUTES = {
  SHIPMENT_LIST: {
    name: 'Shipment:List',
    title: 'Shipments',
    icon: 'PhPackage',
    path: '/shipments/:page?/:rows?',
  },
  SHIPMENT_DETAIL: {
    name: 'Shipment:Detail',
    title: 'Shipment',
    icon: 'PhPackage',
    path: '/shipment/:id',
  },
  SHIPMENT_EDIT: {
    name: 'Shipment:Edit',
    title: 'Edit shipment',
    icon: 'PhPackage',
    path: '/shipment/:id/edit',
  },
  SHIPMENT_CREATE: {
    name: 'Shipment:Create',
    title: 'New shipment',
    icon: 'PhPlusCircle',
    path: '/shipment/new',
  },
  SHIPMENT_LIST_TEST: {
    name: 'Shipment:List:Test',
    title: 'Test shipments',
    icon: 'PhPackage',
    path: '/test/shipments/:page?/:rows?',
  },
  SHIPMENT_DETAIL_TEST: {
    name: 'Shipment:Detail:Test',
    title: 'Shipment',
    icon: 'PhPackage',
    path: '/test/shipment/:id',
  },
  SHIPMENT_EDIT_TEST: {
    name: 'Shipment:Edit:Test',
    title: 'Edit test shipment',
    icon: 'PhPackage',
    path: '/test/shipment/:id/edit',
  },
  SHIPMENT_CREATE_TEST: {
    name: 'Shipment:Create:Test',
    title: 'New test shipment',
    icon: 'PhPackage',
    path: '/test/shipment/new',
  },
  SHIPMENT_TRACKING_DETAIL: {
    name: 'Shipment:Tracking:Detail',
    title: 'Track your Shipment',
    icon: 'PhPackage',
    path: '/track-shipment',
  },
  SHIPMENT_ORDER_REQUEST: {
    name: 'Shipment:OrderRequest:DetailNew',
    title: 'Request for a new Shipment Order',
    icon: 'PhPackage',
    path: '/order-request',
  },
  SHIPMENT_ORDER_REQUEST_DETAIL: {
    name: 'Shipment:OrderRequest:Detail',
    title: 'Request for a new Shipment Order',
    icon: 'PhPackage',
    path: '/order-request',
  },
};

const CONSIGNMENT_ROUTES = {
  CONSIGNMENT_LIST: {
    name: 'Consignment:List',
    title: 'Consignments',
    icon: 'PhTruckTrailer',
    path: '/consignments',
  },
  CONSIGNMENT_LIST_TEST: {
    name: 'Consignment:List:Test',
    title: 'Consignments Playground',
    icon: 'PhTruckTrailer',
    path: '/test/consignments',
  },
  CONSIGNMENT_CREATE: {
    name: 'Consignment:Create',
    title: 'New consignment',
    icon: 'PhTruckTrailer',
    path: '/consignment/new',
  },
  CONSIGNMENT_CREATE_TEST: {
    name: 'Consignment:Create:Test',
    title: 'New consignment',
    icon: 'PhTruckTrailer',
    path: '/test/consignment/new',
  },
  CONSIGNMENT_EDIT: {
    name: 'Consignment:Edit',
    title: 'Edit consignment',
    icon: 'PhTruckTrailer',
    path: '/consignment/:id/edit',
  },
  CONSIGNMENT_EDIT_TEST: {
    name: 'Consignment:Edit:Test',
    title: 'Edit consignment',
    icon: 'PhTruckTrailer',
    path: '/test/consignment/:id/edit',
  },
  CONSIGNMENT_DETAIL: {
    name: 'Consignment:Detail',
    title: 'Consignment',
    icon: 'PhTruckTrailer',
    path: '/consignment/:id',
  },
  CONSIGNMENT_DETAIL_TEST: {
    name: 'Consignment:Detail:Test',
    title: 'Consignment',
    icon: 'PhTruckTrailer',
    path: '/test/consignment/:id',
  },
  CONSIGNMENT_TRACKING_LIST: {
    name: 'Consignment:Tracking:List',
    title: 'Tracking',
    icon: 'PhTruckTrailer',
    path: '/consignments/tracking',
  },
  CONSIGNMENT_TRACKING_DETAIL: {
    name: 'Consignment:Tracking:Detail',
    title: 'Tracking',
    icon: 'PhTruckTrailer',
    path: '/consignments/tracking/:id',
  },
  CONSIGNMENT_TRACKING_DETAIL_ACCEPTANCE: {
    name: 'Consignment:Tracking:Detail:Acceptance',
    title: 'Tracking',
    icon: 'PhTruckTrailer',
    path: '/consignments/tracking/acceptance/:id',
  },
  CONSIGNMENT_URLSIGNER: {
    name: 'Consignment:UrlSigner',
    title: 'Tracking',
    icon: 'PhTruckTrailer',
    path: '/consignment/url-signer',
  },
  CONSIGNMENT_ORDER_REQUEST: {
    name: 'Consignment:OrderRequest:DetailNew',
    title: 'Request for a Consignment Order',
    icon: 'PhPackage',
    path: '/consignment/order-request',
  },
  CONSIGNMENT_ORDER_REQUEST_DETAIL: {
    name: 'Consignment:OrderRequest:Detail',
    title: 'Request for a Consignment Order',
    icon: 'PhPackage',
    path: '/consignment/order-request?signedUrl=:baseUrl/api/shipping/v3/consignments/:id/order-request',
  },
};

const FTP_ROUTES = {
  FTP_LIST: {
    name: 'FTP:List',
    title: 'FTP connections',
    icon: 'PhTreeView',
    path: '/configuration/ftp',
  },
  FTP_CLIENT_CREATE: {
    name: 'FTP:Client:Create',
    title: 'New FTP client',
    icon: 'PhTreeView',
    path: '/configuration/ftp/client/new',
  },
  FTP_CLIENT_EDIT: {
    name: 'FTP:Client:Edit',
    title: 'Edit FTP client',
    icon: 'PhTreeView',
    path: '/configuration/ftp/client/:reference',
  },
  FTP_CLIENT_LOG_LIST: {
    name: 'FTP:Client:LogList',
    title: 'FTP client logs',
    icon: 'PhTreeView',
    path: '/configuration/ftp/client/:reference/logs',
  },
  FTP_SERVER_CREATE: {
    name: 'FTP:Server:Create',
    title: 'New FTP server',
    icon: 'PhTreeView',
    path: '/configuration/ftp/server/new',
  },
  FTP_SERVER_EDIT: {
    name: 'FTP:Server:Edit',
    title: 'Edit FTP server',
    icon: 'PhTreeView',
    path: '/configuration/ftp/server/:reference',
  },
  FTP_SERVER_LOG_LIST: {
    name: 'FTP:Server:LogList',
    title: 'FTP server logs',
    icon: 'PhTreeView',
    path: '/configuration/ftp/server/:reference/logs',
  },
  FTP_CREDENTIAL_REQUEST: {
    name: 'FTP:Credential:Request',
    title: 'Request FTP credentials',
    icon: 'PhTreeView',
    path: '/configuration/ftp/credentials-request',
  },
};

const LOCATION_ROUTES = {
  LOCATION_LIST: {
    name: 'Location:List',
    title: 'Locations',
    icon: 'PhWarehouse',
    path: '/configuration/locations',
  },
  LOCATION_CREATE: {
    name: 'Location:Create',
    title: 'New location',
    icon: 'PhLocation',
    path: '/configuration/location/new',
  },
  LOCATION_DETAIL: {
    name: 'Location:Detail',
    title: 'Location',
    icon: 'PhLocation',
    path: '/configuration/location/:reference',
  },
  LOCATION_EDIT: {
    name: 'Location:Edit',
    title: 'Edit location',
    icon: 'PhLocation',
    path: '/configuration/location/:reference/edit',
  },
};

const RATES_ROUTES = {
  RATE_CONTRACT_LIST: {
    name: 'Rate:Contract:List',
    title: 'Rate contracts',
    icon: 'PhCoins',
    path: '/configuration/rates/contracts',
  },
  RATE_SERVICE_LEVEL_CREATE: {
    name: 'Rate:ServiceLevel:Create',
    title: 'New service level',
    icon: 'PhCoins',
    path: '/configuration/rates/service-level/new',
  },
  RATE_SERVICE_LEVEL_EDIT: {
    name: 'Rate:ServiceLevel:Edit',
    title: 'Edit service level',
    icon: 'PhCoins',
    path: '/configuration/rates/service-level/edit',
  },
  RATE_LOAD: {
    name: 'Rate:Load',
    title: 'Import contract data',
    icon: 'PhCoins',
    path: '/configuration/rates/load',
  },
};

const TOKEN_ROUTES = {
  TOKEN_LIST: {
    name: 'Token:List',
    title: 'Tokens',
    icon: 'PhKey',
    path: '/configuration/tokens',
  },
  TOKEN_CREATE: {
    name: 'Token:Create',
    title: 'New token',
    icon: 'PhKey',
    path: '/configuration/token/new',
  },
  TOKEN_DETAIL: {
    name: 'Token:Detail',
    title: 'Token',
    icon: 'PhKey',
    path: '/configuration/token/:hash',
  },
};

const USER_ROUTES = {
  USER_LIST: {
    name: 'User:List',
    title: 'Users',
    icon: 'PhUsersThree',
    path: '/configuration/users',
  },
  USER_CREATE: {
    name: 'User:Create',
    title: 'New user',
    icon: 'PhUser',
    path: '/configuration/user/new',
  },
  USER_EDIT: {
    name: 'User:Edit',
    title: 'Edit user',
    icon: 'PhUser',
    path: '/configuration/user/:reference/edit',
  },
  USER_DETAIL: {
    name: 'User:Detail',
    title: 'User',
    icon: 'PhUser',
    path: '/configuration/user/:reference',
  },
  USER_PROFILE: {
    name: 'User:Profile',
    title: 'User profile',
    icon: 'PhUser',
    path: '/user/profile',
  },
};

const WEBHOOK_ROUTES = {
  WEBHOOK_LIST: {
    name: 'Webhook:List',
    title: 'Webhooks',
    icon: 'PhWebhooksLogo',
    path: '/configuration/webhooks',
  },
};

const REFERENCE_ROUTES = {
  REFERENCE_LIST: {
    name: 'Reference:List',
    title: 'Reference generator',
    icon: 'PhBarcode',
    path: '/configuration/references',
  },
};

const SHIPMENT_CONFIG_ROUTES = {
  SHIPMENT_CONFIG: {
    name: 'Shipment:Config',
    title: 'Shipment settings',
    icon: 'PhWrench',
    path: '/configuration/shipments',
  },
};

const CARRIER_CONFIG_ROUTES = {
  CARRIER_PROFILE_LIST: {
    name: 'Carrier:Profile:List',
    title: 'Carrier profiles',
    icon: 'PhTruck',
    path: '/configuration/carrier/profiles/:location',
  },
  CARRIER_PROFILE_EDIT: {
    name: 'Carrier:Profile:Edit',
    title: 'Edit carrier profile',
    icon: 'PhTruck',
    path: '/configuration/carrier/profiles/:location/:carrierProfileReference',
  },
  CARRIER_PROFILE_ONBOARDING: {
    name: 'Carrier:Profile:Onboarding',
    title: 'Carrier profile onboarding',
    icon: 'PhTruck',
    path: '/configuration/carrier/:location/:carrierProfileReference/onboarding',
  },
  CARRIER_PROFILE_INTEGRATION_EDIT: {
    name: 'Carrier:Profile:Integration:Edit',
    title: 'Edit carrier profile integration',
    icon: 'PhTruck',
    path: '/configuration/carrier/:location/:carrierProfileReference/integration/:type',
  },
  CARRIER_LOCATION_LIST: {
    name: 'Carrier:Location:LOCATION_LIST',
    title: 'Choose your location',
    icon: 'PhTruck',
    path: '/configuration/carrier',
  },
  CARRIER_PROFILE_CREATE: {
    name: 'Carrier:Profile:Create',
    title: 'New carrier profile',
    icon: 'PhTruck',
    path: '/configuration/carrier/:location/new',
  },
};

const HANDLING_UNIT_ROUTES = {
  HANDLING_UNIT_DETAIL: {
    name: 'HandlingUnit:Detail',
    title: 'Handling unit',
    icon: 'PhPackage',
    path: '/handling-unit/:id',
  },
};

const MISC_ROUTES = {
  DEV_MODE: {
    name: 'Misc:DevMode',
    title: 'Dev mode enablement',
    icon: 'PhPopcorn',
    path: '/configuration/devmode',
  },
};

const INTERNAL_ROUTES = {
  INTERNAL_LIST: {
    name: 'Internal:List',
    title: 'Internal',
    path: '/internal',
  },
  INTERNAL_JSON_FORMS: {
    name: 'Internal:JsonForms',
    title: 'JSON forms example',
    path: '/internal/json-forms',
  },
  INTERNAL_JSON_FORMS_PREVIEW: {
    name: 'Internal:JsonFormsPreview',
    title: 'JSON forms preview',
    path: '/internal/json-forms-preview',
  },
  INTERNAL_OPA_STATUS: {
    name: 'Internal:OpaStatus',
    title: 'OPA overview',
    path: '/internal/opa',
  },
};

export const ROUTES = {
  ...ADDRESS_ROUTES,
  ...SHIPMENT_ROUTES,
  ...CONSIGNMENT_ROUTES,
  ...FTP_ROUTES,
  ...LOCATION_ROUTES,
  ...RATES_ROUTES,
  ...TOKEN_ROUTES,
  ...USER_ROUTES,
  ...WEBHOOK_ROUTES,
  ...REFERENCE_ROUTES,
  ...SHIPMENT_CONFIG_ROUTES,
  ...CARRIER_CONFIG_ROUTES,
  ...HANDLING_UNIT_ROUTES,
  ...MISC_ROUTES,
  ...INTERNAL_ROUTES,
} as const;

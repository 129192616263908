import { defineStore } from 'pinia';

import { type components as printing } from '@/generated/printing';
import { PrinterService } from '@/services/printing/printer-service.ts';

import { FeatureFlag, useFeatureFlagsStore } from './__tests__/feature-flags.ts';
type Printer = printing['schemas']['Printer'];

const printingService = new PrinterService();

export const usePrinterStore = defineStore('printers', {
  state: () => ({
    printers: [] as Printer[],
  }),

  actions: {
    async tryGetPrinters() {
      const featureFlagStore = useFeatureFlagsStore();
      if (featureFlagStore.isFlagEnabled(FeatureFlag.PrintNode)) {
        const response = await printingService.readListWithQuery();
        if (response.isSuccess()) this.printers = response.data.items;
        else console.warn('Failed to fetch printers');
      }
    },
  },
});

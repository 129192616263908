import { useLocalStorage } from '@vueuse/core';
import { defineStore } from 'pinia';

import { ConfigsLocationService } from '@/services/configs/configs-location-service';

interface RefLabel {
  reference: string;
  label: string;
}

const locationService = new ConfigsLocationService();

export const useContextStore = defineStore('context', {
  state: () => {
    return {
      locationReference: localStorage.getItem('locationReference') as string,
      locations: [] as RefLabel[],
      devMode: useLocalStorage('devmode', !import.meta.env.PROD as boolean),
      isLoading: false,
      locationsLoaded: false,
    };
  },
  getters: {
    getLocations: (state) => state.locations,
    locationCount: (state) => state.locations.length,
    getLocation: (state) => {
      const current = state.locations.filter((location) => {
        if (state.locationReference === location.reference) {
          return location;
        }
      });

      if (current.length > 0) {
        return current[0];
      } else {
        return undefined;
      }
    },
    getDevMode: (state) => state.devMode,
  },
  actions: {
    async loadLocations() {
      this.isLoading = true;
      this.locations = await getLocations();
      if (
        !this.locationReference ||
        !this.locations.some((l) => l.reference === this.locationReference)
      ) {
        if (this.locations.length > 0) {
          const firstLocationReference = this.locations[0]?.reference as string;
          this.setLocation(firstLocationReference);
        }
      }
      this.locationsLoaded = true;
      this.isLoading = false;
    },
    setLocation(reference: string) {
      this.locationReference = reference;
      localStorage.setItem('locationReference', reference);
    },
    setDevMode(value: boolean) {
      this.devMode = value;
    },
  },
});

async function getLocations(): Promise<RefLabel[]> {
  const response = await locationService.readListWithQuery();

  if (!response.isSuccess()) console.log(response.problem);
  return (response.data?.items ?? []).map((item) => ({
    reference: item.reference,
    label: item.label ?? item.reference,
  }));
}

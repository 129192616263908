import { BaseService, DataOrProblem, EmptyResponseOptions, Options } from '@/services/base-service';
import { ApiClient } from '@/services/clients';
import { UserProfile } from '@/services/configs/configs-types';

const parentPath = '/configs/v1/profile';

// redoc at https://stpviyacontracts.z6.web.core.windows.net/?swagger=%252Fconfigs%252Fswagger.json#tag/UserProfile
export class UserProfileService extends BaseService {
  public async readSingle(...args: Options<UserProfile>): Promise<DataOrProblem<UserProfile>> {
    return await this.executeRequest<UserProfile>(
      async () => await ApiClient.GET(parentPath),
      ...args
    );
  }

  public async updateSingle(
    userProfile: UserProfile,
    ...args: EmptyResponseOptions
  ): Promise<DataOrProblem<never>> {
    return await this.executeRequest<never>(
      async () =>
        await ApiClient.PUT(parentPath, {
          body: userProfile,
        }),
      ...args
    );
  }
}

import { type RouteRecordRaw } from 'vue-router';

import { ROUTES } from './helpers/routes';

const FtpConfiguration: Array<RouteRecordRaw> = [
  {
    path: ROUTES.FTP_LIST.path,
    name: ROUTES.FTP_LIST.name,
    component: () => import('@/views/ftp/FtpOverviewPage.vue'),
    meta: {
      title: ROUTES.FTP_LIST.title,
    },
  },
  {
    path: ROUTES.FTP_CLIENT_CREATE.path,
    name: ROUTES.FTP_CLIENT_CREATE.name,
    component: () => import('@/views/ftp/client/FtpClientCreatePage.vue'),
    meta: {
      title: ROUTES.FTP_CLIENT_CREATE.title,
      parents: { 0: ROUTES.FTP_LIST },
    },
  },
  {
    path: ROUTES.FTP_SERVER_CREATE.path,
    name: ROUTES.FTP_SERVER_CREATE.name,
    component: () => import('@/views/ftp/server/FtpServerCreatePage.vue'),
    meta: {
      title: ROUTES.FTP_SERVER_CREATE.title,
      parents: { 0: ROUTES.FTP_LIST },
    },
  },
  {
    path: ROUTES.FTP_CLIENT_EDIT.path,
    name: ROUTES.FTP_CLIENT_EDIT.name,
    component: () => import('@/views/ftp/client/FtpClientCreatePage.vue'),
    meta: {
      title: ROUTES.FTP_CLIENT_EDIT.title,
      parents: { 0: ROUTES.FTP_LIST },
    },
  },
  {
    path: ROUTES.FTP_SERVER_EDIT.path,
    name: ROUTES.FTP_SERVER_EDIT.name,
    component: () => import('@/views/ftp/server/FtpServerCreatePage.vue'),
    meta: {
      title: ROUTES.FTP_SERVER_EDIT.title,
      parents: { 0: ROUTES.FTP_LIST },
    },
  },
  {
    path: ROUTES.FTP_CLIENT_LOG_LIST.path,
    name: ROUTES.FTP_CLIENT_LOG_LIST.name,
    component: () => import('@/views/ftp/client/FtpClientLogsOverviewPage.vue'),
    meta: {
      title: ROUTES.FTP_CLIENT_LOG_LIST.title,
      parents: { 0: ROUTES.FTP_LIST, 1: ROUTES.FTP_CLIENT_EDIT },
    },
  },
  {
    path: ROUTES.FTP_SERVER_LOG_LIST.path,
    name: ROUTES.FTP_SERVER_LOG_LIST.name,
    component: () => import('@/views/ftp/server/FtpServerLogsOverviewPage.vue'),
    meta: {
      title: ROUTES.FTP_SERVER_LOG_LIST.title,
      parents: { 0: ROUTES.FTP_LIST, 1: ROUTES.FTP_SERVER_EDIT },
    },
  },
  {
    path: ROUTES.FTP_CREDENTIAL_REQUEST.path,
    name: ROUTES.FTP_CREDENTIAL_REQUEST.name,
    component: () => import('@/views/ftp/FtpCredentialRequestPage.vue'),
    meta: {
      title: ROUTES.FTP_CREDENTIAL_REQUEST.title,
      parents: { 0: ROUTES.FTP_LIST },
      allowAnonymous: true,
    },
  },
];

export default FtpConfiguration;

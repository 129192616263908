import { BaseService, DataOrProblem, EmptyResponseOptions, Options } from '@/services/base-service';
import { ApiClient } from '@/services/clients';
import { GetPrintersQuery, PrinterListWrapper } from '@/services/printing/printing-types';

const parentPath = '/printing/v1/printers';

// redoc at https://stpviyacontracts.z6.web.core.windows.net/?swagger=%252Fprinting%252Fv1%252Fswagger.json#tag/Printers
export class PrinterService extends BaseService {
  public async readListWithQuery(
    query: GetPrintersQuery = undefined,
    ...args: Options<PrinterListWrapper>
  ): Promise<DataOrProblem<PrinterListWrapper>> {
    return await this.executeRequest<PrinterListWrapper>(
      async () => await ApiClient.GET(parentPath, { params: { query } }),
      ...args
    );
  }

  public async resyncAll(...args: EmptyResponseOptions): Promise<DataOrProblem<never>> {
    return await this.executeRequest<never>(async () => await ApiClient.PUT(parentPath), ...args);
  }

  public async deleteAllWebhooks(
    apiReference: string,
    ...args: EmptyResponseOptions
  ): Promise<DataOrProblem<never>> {
    return await this.executeRequest<never>(
      async () =>
        await ApiClient.DELETE(`${parentPath}/webhook/{apiRef}`, {
          params: {
            path: { apiRef: apiReference },
          },
        }),
      ...args
    );
  }
}

<template>
  <PopoverComponent
    @selected="handleSelect"
    :options="options"
    alignment="end"
    data-testid="user.options"
  >
    <ButtonComponent
      variant="ghost"
      :data-testid="baseTestId + '.user.trigger'"
      icon="PhUserCircle"
    />
  </PopoverComponent>
</template>

<script setup lang="ts">
import { Configuration, FrontendApi } from '@ory/client';
import { ButtonComponent, PopoverComponent } from '@shipitsmarter/viya-ui-warehouse';
import { onMounted, Ref, ref } from 'vue';
import { useRouter } from 'vue-router';

import { ROUTES } from '@/router/helpers/routes';

const router = useRouter();
const baseTestId = 'nav-bar';
const logoutUrl: Ref<string> = ref('');

const ory = new FrontendApi(
  new Configuration({
    basePath: '/auth/ory',
    baseOptions: {
      withCredentials: true,
    },
  })
);

async function setLogoutUrl() {
  if (import.meta.env.PROD) {
    ory.createBrowserLogoutFlow().then(({ data }) => {
      logoutUrl.value =
        data.logout_url + '&return_to=' + encodeURIComponent(window.location.origin);
    });
  }
}

const logout = () => {
  window.location.href = logoutUrl.value;
};

onMounted(async () => {
  await setLogoutUrl();
});

const handleSelect = ({ route, action }: { route: string; action: () => void }) => {
  if (action) {
    action();
  } else {
    router.push({ name: route });
  }
};

const options = [
  {
    text: 'Profile',
    icon: 'PhUserCircle',
    route: ROUTES.USER_PROFILE.name,
  },
  {
    text: 'Logout',
    icon: 'PhSignOut',
    action: logout,
  },
];
</script>

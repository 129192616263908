import { isOk, untypedClient } from '@/services/clients';

export interface AccessMeta {
  method: 'POST' | 'GET' | 'PATCH' | 'PUT' | 'DELETE';
  service: string;
  url: string;
  version: string;
}

export async function checkAccess(access: AccessMeta): Promise<boolean> {
  const url = `/auth/decisions/${access.method}/api/${access.service}/${access.version}/${access.url}`;
  return checkAccessByUrl(url);
}

export async function checkAccessByUrl(url: string): Promise<boolean> {
  try {
    const res = await untypedClient.GET(url);
    return isOk(res);
  } catch (error) {
    console.log('Decision check failed', error);
    return false;
  }
}

import { defineStore } from 'pinia';

import { type components as configs } from '@/generated/configs';
import { AccessKey } from '@/plugins/authorization';
import { isOk, untypedClient } from '@/services/clients';
import { UserProfile } from '@/services/configs/configs-types';
import { UserProfileService } from '@/services/configs/user-profile-service';
import createUntypedClient from '@/services/untypedClient';
import { sendTrackingEvent } from '@/utils/logger';

type Printer = configs['schemas']['PrinterDetails'];

const userProfileService = new UserProfileService();
const opaClient = createUntypedClient({ baseUrl: '/api/opa/v1' });

type userRoles = {
  decision_id: '';
  result: {
    roles: string[];
  };
};
export interface UserInfo {
  identity: {
    id: string;
    traits: {
      email: string;
      name: string;
    };
    organization_id?: string | null; // test
  };
}

export interface iAccess extends Record<AccessKey, boolean> {}

export const useUserStore = defineStore('users', {
  state: () => {
    return {
      info: undefined as UserInfo | undefined,
      profile: undefined as UserProfile | undefined,
      access: {} as iAccess,
      error: '',
      roles: {
        result: {},
      } as userRoles,
      expiryWindows: ['3 Months', '6 Months', '1 Year'],
    };
  },
  getters: {
    isAuthenticated: (state): boolean => state.info !== undefined,
    getAccess: (state): iAccess => state.access,
  },
  actions: {
    async tryGetUserInfo(): Promise<boolean> {
      if (import.meta.env.PROD) {
        try {
          this.info = await getUserInfo();
          return true;
        } catch {
          return false;
        }
      }

      // development
      this.info = localDevUser;
      return true;
    },
    async tryGetUserProfile(): Promise<boolean> {
      try {
        this.profile = await getUserProfile();
        return true;
      } catch {
        return false;
      }
    },
    async setProfile(profile: UserProfile): Promise<boolean> {
      const response = await userProfileService.updateSingle(profile);
      if (response.isSuccess()) this.profile = profile;
      else console.warn('Failed to save user profile');

      return response.isSuccess();
    },
    async toggleFavoriteAddress(addressId: string): Promise<boolean> {
      if (this.profile === undefined) {
        return false;
      }

      // Toggle the favorite state
      const favoriteState = this.profile.favoriteAddresses.includes(addressId);
      if (favoriteState) {
        this.profile.favoriteAddresses = this.profile.favoriteAddresses.filter(
          (item) => item !== addressId
        );
        sendTrackingEvent('addresses.item.toggled_favorite', { value: false }); //  unknown source, address list or address select
      } else {
        this.profile.favoriteAddresses.push(addressId);
        sendTrackingEvent('addresses.item.toggled_favorite', { value: true });
      }
      await this.setProfile(this.profile);

      return true;
    },
    async setDefaultPrinter(key: string, printer: Printer): Promise<boolean> {
      if (this.profile === undefined) {
        return false;
      }

      if (!this.profile.defaultPrinters) {
        this.profile.defaultPrinters = {};
      }

      this.profile.defaultPrinters[key] = printer;
      await this.setProfile(this.profile);

      return true;
    },
    async getAvailableRoles() {
      const res = await opaClient.GET('/data/get_role_keys');

      if (!isOk(res)) {
        console.error(res);
        this.error = 'Failed to get available roles.';

        return;
      }
      this.roles = res.data as userRoles;
    },

    updateAccess(key: AccessKey, value: boolean) {
      this.access[key] = value;
    },
    updateMultipleAccess(data: Partial<iAccess>) {
      this.access = { ...this.access, ...data };
    },
  },
});

const localDevUser: UserInfo = {
  identity: {
    id: 'local-dev',
    traits: {
      name: 'local-dev',
      email: 'dev@localhost',
    },
  },
};

async function getUserInfo(): Promise<UserInfo> {
  const res = await untypedClient.GET('/auth/userinfo');
  if (!isOk(res)) {
    throw new Error('Something went wrong while trying to authenticate you.');
  }
  return res.data as UserInfo;
}

async function getUserProfile(): Promise<UserProfile> {
  const response = await userProfileService.readSingle();
  if (!response.isSuccess())
    throw new Error('Something went wrong while trying grab user profile.');
  return response.data;
}

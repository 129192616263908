import { type RouteRecordRaw } from 'vue-router';

import { ROUTES } from './helpers/routes';

const Rates: Array<RouteRecordRaw> = [
  {
    path: ROUTES.RATE_CONTRACT_LIST.path,
    name: ROUTES.RATE_CONTRACT_LIST.name,
    component: () => import('@/views/rates/RatesContractOverviewPage.vue'),
    meta: {
      title: ROUTES.RATE_CONTRACT_LIST.title,
    },
  },
  {
    path: ROUTES.RATE_LOAD.path,
    name: ROUTES.RATE_LOAD.name,
    component: () => import('@/views/rates/configuration/RateEditPage.vue'),
    props: true,
    meta: {
      title: ROUTES.RATE_LOAD.title,
      parents: { 0: ROUTES.RATE_CONTRACT_LIST, 1: ROUTES.RATE_SERVICE_LEVEL_EDIT },
    },
  },
  {
    path: ROUTES.RATE_SERVICE_LEVEL_CREATE.path,
    name: ROUTES.RATE_SERVICE_LEVEL_CREATE.name,
    component: () => import('@/views/rates/service-levels/ServiceLevelEditPage.vue'),
    props: {
      new: true,
    },
    meta: {
      title: ROUTES.RATE_SERVICE_LEVEL_CREATE.title,
      parents: { 0: ROUTES.RATE_CONTRACT_LIST },
    },
  },
  {
    path: ROUTES.RATE_SERVICE_LEVEL_EDIT.path,
    name: ROUTES.RATE_SERVICE_LEVEL_EDIT.name,
    component: () => import('@/views/rates/service-levels/ServiceLevelEditPage.vue'),
    props: {
      new: false,
    },
    meta: {
      title: ROUTES.RATE_SERVICE_LEVEL_EDIT.title,
      parents: { 0: ROUTES.RATE_CONTRACT_LIST },
    },
  },
];

export default Rates;
